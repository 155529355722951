import React from 'react'
import { Form, Input, Button, DatePicker, Row, Col, Checkbox } from 'antd'
import { disabledDate } from '../share/helpers'

const SearchForm = ({ handleSearch, resetFields, form }) => {
  const { getFieldDecorator, getFieldValue } = form

  return (
    <Form
      className="search-form"
      onKeyUp={e => (e.keyCode === 13 || e.which === 13) && handleSearch()}
    >
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="Name">
            {getFieldDecorator('name')(<Input />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Email address">
            {getFieldDecorator('email')(
              <Input placeholder="email@example.com" />
            )}
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item label="Deputy">
            {getFieldDecorator('deputy')(<Input />)}
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item label="Date of creation" style={{ marginBottom: 0 }}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('createDateFrom')(
                    <DatePicker
                      disabledDate={from =>
                        disabledDate(from, getFieldValue('createDateTo'))
                      }
                      placeholder="From"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('createDateTo')(
                    <DatePicker
                      disabledDate={to =>
                        disabledDate(getFieldValue('createDateFrom'), to)
                      }
                      placeholder="To"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last logged in" style={{ marginBottom: 0 }}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('lastLoginFrom')(
                    <DatePicker
                      disabledDate={from =>
                        disabledDate(from, getFieldValue('lastLoginTo'))
                      }
                      placeholder="From"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator('lastLoginTo')(
                    <DatePicker
                      disabledDate={to =>
                        disabledDate(getFieldValue('lastLoginFrom'), to)
                      }
                      placeholder="To"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        {getFieldDecorator('marketingFromVaultbox', {
          valuePropName: 'checked'
        })(<Checkbox>Receive marketing materials from Bantex</Checkbox>)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('marketingFromThirdParties', {
          valuePropName: 'checked'
        })(<Checkbox>Receive marketing materials from third parties</Checkbox>)}
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleSearch}>
          Search
        </Button>
        &nbsp;
        <Button type="default" onClick={resetFields}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  )
}
const WrappedSearchForm = Form.create({ name: 'SearchForm' })(SearchForm)
export default WrappedSearchForm
