import React, { useState } from 'react'
import { Form, Modal, Alert, message } from 'antd'
import api from '../lib/api'
import AddMultipleFields from './AddMultipleFields'

function SharePromotionCodeModal({
  visible,
  setVisible,
  promoCode,
  promoCodeName,
  fetchPromoCodes,
  form
}) {
  const [err, setErr] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { getFieldDecorator } = form

  const sendEmails = e => {
    setErr('')
    form.validateFields((err, values) => {
      if (err) return
      if (!values.emails?.length) {
        setErr('Please enter at least 1 email address.')
        return
      }

      setIsSending(true)
      const sharedEmails = values.emails?.map(e => e.email)

      api
        .sendPromotionEmails(
          JSON.stringify({
            sharedEmails,
            promoCode,
            promoCodeName
          })
        )
        .then(res => {
          fetchPromoCodes()
          message.success('Successfully sent emails')
          reset()
        })
        .catch(err => {
          setErr(err.message || 'Failed to send emails')
          console.log(err)
        })
        .finally(() => setIsSending(false))
    })
  }

  const reset = () => {
    setErr('')
    setVisible(false)
    form.resetFields()
  }

  return (
    <Modal
      title="Share promotion code via email"
      visible={visible}
      okText="Send"
      okButtonProps={{
        loading: isSending
      }}
      onOk={sendEmails}
      onCancel={reset}
      width={700}
    >
      <>
        <h4>Promotion code: {promoCode}</h4>
        <h4>Promotion name: {promoCodeName}</h4>
        <Form>
          <Form.Item label="Email addresses">
            <AddMultipleFields
              name="emails"
              title="email address"
              fields={[{ key: 'email', placeholder: 'email@example.com' }]}
              getFieldDecorator={getFieldDecorator}
              customRules={[
                {
                  fieldKey: 'email',
                  rules: [
                    {
                      type: 'email',
                      message: 'Enter a valid email address'
                    }
                  ]
                }
              ]}
            />
          </Form.Item>
        </Form>
        {err && (
          <Alert
            message={err}
            type="error"
            closable
            afterClose={() => setErr('')}
          />
        )}
      </>
    </Modal>
  )
}

const WrappedSharePromotionCodeModal = Form.create({
  name: 'SharePromotionCodeModal'
})(SharePromotionCodeModal)

export default WrappedSharePromotionCodeModal
