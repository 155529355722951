import React, { useState, useEffect } from 'react'
import { Form, Input, Alert, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { CognitoUser } from 'amazon-cognito-identity-js'
import {
  getUserPool,
  getAuthenticationDetails,
  getUserAttributeValue
} from '../lib/cognito'
import { setApiInterceptor } from '../lib/api'
import NewPasswordForm from './NewPasswordForm'
import { MFA_TYPES } from '../share/constants'
import SetupMFA from './SetupMFA'
import MfaForm from './MfaVerificationForm'
import config from '../config'
import TextInput from './TextInput'

let cognitoUser, mfaSecretCode, authDetails, callbacks, reqAttributes, formRef

const LOGIN_STEPS = {
  LOGIN: 'login',
  NEW_PASSWORD: 'new_password',
  SETUP_MFA: 'setup_mfa',
  ...MFA_TYPES
}

function LoginForm(props) {
  const { setIsAuthenticated, setUser, history, form } = props
  const { getFieldDecorator } = form
  const [errMsg, setErrMsg] = useState('')
  const [loginStep, setLoginStep] = useState(LOGIN_STEPS.LOGIN)
  const [currentStep, setCurrentStep] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [confirmDirty, setConfirmDirty] = useState(false)

  useEffect(() => {
    if (errMsg) setIsSubmitting(false)
  }, [errMsg])

  // Verify token filled in by user
  const verifySoftwareToken = token => {
    setIsSubmitting(true)
    setErrMsg('')

    cognitoUser.verifySoftwareToken(token, 'My TOTP device', {
      onSuccess: function () {
        // if input token is valid, then enable MFA for specific user, with SOFTWARE_TOKEN_MFA type
        cognitoUser.setUserMfaPreference(
          null,
          { PreferredMfa: true, Enabled: true },
          function (err) {
            if (err) {
              setErrMsg(err.message)
              return
            }
            setCurrentStep(2)
          }
        )
      },
      onFailure: err => {
        setErrMsg(err.message)
      }
    })
  }

  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      setIsSubmitting(true)
      const authenticationDetails = getAuthenticationDetails(
        values.username,
        values.password
      )
      cognitoUser = new CognitoUser({
        Username: values.username,
        Pool: getUserPool()
      })

      authDetails = { ...values }

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function () {
          cognitoUser.getUserData(
            (err, res) => {
              if (err) {
                setErrMsg(err.message)
                return
              }
              const bypassMFA = getUserAttributeValue(
                res.UserAttributes,
                'custom:bypass_mfa'
              )

              if (
                res.PreferredMfaSetting === MFA_TYPES.TOTP ||
                (config.env !== 'prod' && bypassMFA === 'true')
              ) {
                setUser(cognitoUser)
                setApiInterceptor(cognitoUser)
                setIsAuthenticated(true)

                history.push('/')
              } else {
                cognitoUser.associateSoftwareToken(this)
              }
            },
            { bypassCache: true }
          )
        },

        onFailure: function (err) {
          setErrMsg(err.message)
        },

        associateSecretCode: function (secretCode) {
          setIsSubmitting(false)
          mfaSecretCode = secretCode
          setLoginStep(LOGIN_STEPS.SETUP_MFA)
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified

          reqAttributes = requiredAttributes
          setIsSubmitting(false)
          setLoginStep(LOGIN_STEPS.NEW_PASSWORD)
        },

        totpRequired: function () {
          callbacks = this
          setIsSubmitting(false)
          setLoginStep(LOGIN_STEPS.TOTP)
        }
      })
    })
  }

  const handleChangePassword = e => {
    e.preventDefault()
    formRef.props.form.validateFields((err, values) => {
      if (err) return

      setIsSubmitting(true)
      cognitoUser.completeNewPasswordChallenge(values.password, reqAttributes, {
        onSuccess: function (session) {
          cognitoUser.getUserData(
            (err, res) => {
              if (err) {
                setErrMsg(err.message)
                return
              }
              const bypassMFA = getUserAttributeValue(
                res.UserAttributes,
                'custom:bypass_mfa'
              )

              if (config.env !== 'prod' && bypassMFA === 'true') {
                setUser(cognitoUser)
                setApiInterceptor(cognitoUser)
                setIsAuthenticated(true)

                history.push('/')
              } else {
                cognitoUser.associateSoftwareToken(this)
              }
            },
            { bypassCache: true }
          )
        },
        onFailure: function (err) {
          setIsSubmitting(false)
          setErrMsg(err.message)
        },
        associateSecretCode: function (secretCode) {
          setIsSubmitting(false)
          mfaSecretCode = secretCode
          setLoginStep(LOGIN_STEPS.SETUP_MFA)
        }
      })
    })
  }

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  return loginStep === LOGIN_STEPS.LOGIN ? (
    <Form
      onSubmit={handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark={true}
    >
      <div className="form-header">
        <h3>Sign in</h3>
        <p>
          Welcome back to <b>Bantex Hybrid eFiling</b> admin portal
        </p>
      </div>
      {errMsg && (
        <Alert
          message={errMsg}
          type="error"
          closable
          style={{ marginBottom: 16 }}
        />
      )}
      <Form.Item label="Email address">
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: 'Enter your email address'
            }
          ]
        })(<TextInput placeholder="email@example.com" allowClear />)}
      </Form.Item>
      <Form.Item label="Password">
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Enter your password' }]
        })(<Input.Password placeholder="Password" maxLength={30} />)}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          block
          style={{ margin: '10px 0' }}
          loading={isSubmitting}
        >
          Sign in
        </Button>
      </Form.Item>
    </Form>
  ) : loginStep === LOGIN_STEPS.NEW_PASSWORD ? (
    <NewPasswordForm
      wrappedComponentRef={fr => (formRef = fr)}
      handleChangePassword={handleChangePassword}
      confirmDirty={confirmDirty}
      handleConfirmBlur={handleConfirmBlur}
      isSubmitting={isSubmitting}
    />
  ) : loginStep === LOGIN_STEPS.SETUP_MFA ? (
    <div style={{ marginTop: 30, minHeight: 600 }}>
      <SetupMFA
        username={authDetails.username}
        secretCode={mfaSecretCode}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
        isSubmitting={isSubmitting}
        verifySoftwareToken={verifySoftwareToken}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleCancel={() => {
          cognitoUser.signOut()
          mfaSecretCode = undefined
          setLoginStep(LOGIN_STEPS.LOGIN)
        }}
        handleFinish={() => {
          setUser(cognitoUser)
          setApiInterceptor(cognitoUser)
          setIsAuthenticated(true)
          history.push('/')
        }}
      />
    </div>
  ) : loginStep === LOGIN_STEPS.TOTP ? (
    <MfaForm
      mfaType={loginStep}
      cognitoUser={cognitoUser}
      errMsg={errMsg}
      setErrMsg={setErrMsg}
      callbacks={callbacks}
      isSubmitting={isSubmitting}
      setIsSubmitting={setIsSubmitting}
      style={{ width: 300, margin: '150px auto auto' }}
    />
  ) : (
    <></>
  )
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm)
export default withRouter(WrappedLoginForm)
