import React, { useEffect, useState } from 'react'
import api from '../lib/api'
import {
  PageHeader,
  Alert,
  Table,
  Button,
  Icon,
  Tooltip,
  message,
  Popconfirm
} from 'antd'
import AddPartnerCodeModal from './AddPartnerCodeModal'
import copy from 'copy-to-clipboard'
import config from '../config'
// import SharePromotionCodeModal from './SharePromotionCodeModal'
// import PromotionCodeDetailsModal from './PromotionCodeDetailsModal'
// import { PRODUCT_NAME } from '../share/constants'

export default function PartnerCodes() {
  const [partnerCodes, setPartnerCodes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [partnerCodeModalVisible, setPartnerCodeModalVisible] = useState(false)
  // const [shareModalVisible, setShareModalVisible] = useState(false)
  // const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  // const [selectedPromoCode, setSelectedPromoCode] = useState('')
  // const [selectedPromoCodeName, setSelectedPromoCodeName] = useState('')
  // const [plans, setPlans] = useState([])

  const fetchPartnerCodes = () => {
    setIsLoading(true)
    api
      .getPartnerCode()
      .then(res => {
        console.log(res)
        setPartnerCodes(res.data.filter(d => !d.isDeleted))
      })
      .catch(err => {
        console.log(err)
        setError(err.message || 'Failed to list partner codes.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchPartnerCodes()
  }, [])

  const handleDeletePartnerCode = async codeId => {
    try {
      await api.deletePartnerCode(codeId)
      message.success('Successfully deleted partner code')
      fetchPartnerCodes()
    } catch (error) {
      message.error(error.message || 'Failed to delete partner code')
    }
  }

  const copyToClipboard = record => {
    try {
      copy(`${config.site.SITE_URL}/signup?partnerCode=${record.id}`)
      message.success('Successfully copied url')
    } catch (error) {
      message.error('Failed to copy url')
    }
  }

  const columns = [
    {
      key: 'id',
      title: 'Partner code',
      dataIndex: 'id'
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name'
    },
    // {
    //   key: 'isPrivateCode',
    //   title: 'Private',
    //   dataIndex: 'isPrivateCode',
    //   render: text => (text === true ? 'Yes' : null)
    // },
    // {
    //   key: 'appliesToPlan',
    //   title: 'Applies to plan',
    //   dataIndex: 'appliesToPlan',
    //   render: planId => plans.find(p => p.id === planId)?.nickname
    // },
    {
      key: 'appliedUsers',
      title: 'Applied users',
      dataIndex: 'appliedUsers',
      render: users => users?.map(e => <p key={e.email}>{e.email}</p>)
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => (
        <>
          {/* <Tooltip title="Share via email">
            <Icon type="mail" onClick={() => shareViaEmail(record)} />
          </Tooltip>
          <Tooltip title="View details">
            <Icon
              type="unordered-list"
              onClick={() => viewDetails(record)}
              style={{ marginLeft: 8 }}
            />
          </Tooltip> */}
          <Tooltip title="Copy signup url">
            <Icon
              type="copy"
              style={{ marginLeft: 8, cursor: 'pointer' }}
              onClick={() => copyToClipboard(record)}
            />
          </Tooltip>
          <Tooltip title="Delete code">
            <Popconfirm
              title="Are you sure to delete this code?"
              onConfirm={() => handleDeletePartnerCode(record.id)}
              okText="Yes"
              cancelText="No"
              arrowPointAtCenter
              placement="bottomRight"
            >
              <Icon type="delete" style={{ marginLeft: 8 }} />
            </Popconfirm>
          </Tooltip>
        </>
      )
    }
  ]

  return (
    <>
      <PageHeader
        title="Partner codes"
        extra={
          <Button
            type="primary"
            onClick={() => setPartnerCodeModalVisible(true)}
          >
            Add partner code
          </Button>
        }
      />
      {error && <Alert type="error" showIcon message={error} closable />}
      <div style={{ padding: 20 }}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={partnerCodes}
        />
      </div>
      <AddPartnerCodeModal
        visible={partnerCodeModalVisible}
        setVisible={setPartnerCodeModalVisible}
        fetchPartnerCodes={fetchPartnerCodes}
        // plans={plans}
      />
      {/* <SharePromotionCodeModal
        visible={shareModalVisible}
        setVisible={setShareModalVisible}
        promoCode={selectedPromoCode}
        promoCodeName={selectedPromoCodeName}
        fetchPromoCodes={fetchPromoCodes}
      />
      <PromotionCodeDetailsModal
        visible={detailsModalVisible}
        setVisible={setDetailsModalVisible}
        promoCodeId={selectedPromoCode}
      /> */}
    </>
  )
}
