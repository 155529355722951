import React from 'react'
import { Form, Input, Button } from 'antd'
import { debounce } from 'lodash'
import PasswordInputWithRules from './PasswordInputWithRules'

class NewPasswordForm extends React.Component {
  render() {
    const {
      form,
      handleChangePassword,
      confirmDirty,
      handleConfirmBlur,
      isSubmitting
    } = this.props
    const { getFieldDecorator } = form

    return (
      <Form layout="vertical" className="login-form">
        <div className="form-header">
          <h3 style={{ fontSize: 24 }}>New password</h3>
        </div>

        <PasswordInputWithRules
          confirmDirty={confirmDirty}
          title="New password"
          form={form}
        />
        <Form.Item label="Confirm new password" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              { required: true, message: 'Confirm new password' },
              {
                validator: (rule, value, callback) => {
                  if (value && value !== form.getFieldValue('password')) {
                    debounce(
                      callback,
                      800
                    )('The passwords you entered do not match.')
                  } else {
                    debounce(callback, 800)()
                  }
                }
              }
            ]
          })(<Input.Password maxLength={30} onBlur={handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleChangePassword}
            block
            loading={isSubmitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedNewPasswordForm = Form.create({ name: 'newPasswordForm' })(
  NewPasswordForm
)
export default WrappedNewPasswordForm
