import React, { useEffect, useState } from 'react'
import api from '../lib/api'
import {
  PageHeader,
  Alert,
  Table,
  Button,
  Icon,
  Tooltip,
  message,
  Popconfirm
} from 'antd'
import AddPromoCodeModal from './AddPromoCodeModal'
import SharePromotionCodeModal from './SharePromotionCodeModal'
import PromotionCodeDetailsModal from './PromotionCodeDetailsModal'
import { PRODUCT_NAME } from '../share/constants'

export default function PromotionCodes() {
  const [promoCodes, setPromoCodes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [promoCodeModalVisible, setPromoCodeModalVisible] = useState(false)
  const [shareModalVisible, setShareModalVisible] = useState(false)
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [selectedPromoCode, setSelectedPromoCode] = useState('')
  const [selectedPromoCodeName, setSelectedPromoCodeName] = useState('')
  const [plans, setPlans] = useState([])

  const fetchPromoCodes = () => {
    setIsLoading(true)
    api
      .getPromoCodes()
      .then(res => {
        setPromoCodes(res.data.filter(d => !d.isDeleted))
      })
      .catch(err => {
        console.log(err)
        setError(err.message || 'Failed to list promotion codes.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchPromoCodes()

    api
      .getPlans(PRODUCT_NAME.VAULTBOX)
      .then(res => {
        const defaultVaultboxPlans = res?.data?.data
          .filter(p => !p.metadata.discountCode)
          .sort((a, b) => a.amount - b.amount)

        setPlans(defaultVaultboxPlans)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const shareViaEmail = promoCode => {
    setShareModalVisible(true)
    setSelectedPromoCode(promoCode.id)
    setSelectedPromoCodeName(promoCode.promotionName)
  }

  const viewDetails = promoCode => {
    setDetailsModalVisible(true)
    setSelectedPromoCode(promoCode.id)
    setSelectedPromoCodeName(promoCode.promotionName)
  }

  const handleDeletePromoCode = async codeId => {
    try {
      await api.deletePromoCode(codeId)
      message.success('Successfully deleted promotion code')
      fetchPromoCodes()
    } catch (error) {
      message.error(error.message || 'Failed to delete promotion code')
    }
  }

  const columns = [
    {
      key: 'id',
      title: 'Promotion code / Stripe coupon ID',
      dataIndex: 'id'
    },
    {
      key: 'promotionName',
      title: 'Promotion name',
      dataIndex: 'promotionName'
    },
    {
      key: 'isPrivateCode',
      title: 'Private',
      dataIndex: 'isPrivateCode',
      render: text => (text === true ? 'Yes' : null)
    },
    {
      key: 'appliesToPlan',
      title: 'Applies to plan',
      dataIndex: 'appliesToPlan',
      render: planId => plans.find(p => p.id === planId)?.nickname
    },
    {
      key: 'sharedUsers',
      title: 'Shared users',
      dataIndex: 'sharedUsers',
      render: users => users.map(e => <p key={e.email}>{e.email}</p>)
    },
    {
      key: 'appliedUsers',
      title: 'Applied users',
      dataIndex: 'appliedUsers',
      render: users => users.map(e => <p key={e.email}>{e.email}</p>)
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => (
        <>
          <Tooltip title="Share via email">
            <Icon type="mail" onClick={() => shareViaEmail(record)} />
          </Tooltip>
          <Tooltip title="View details">
            <Icon
              type="unordered-list"
              onClick={() => viewDetails(record)}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
          <Tooltip title="Delete code">
            <Popconfirm
              title="Are you sure to delete this code?"
              onConfirm={() => handleDeletePromoCode(record.id)}
              okText="Yes"
              cancelText="No"
              arrowPointAtCenter
              placement="bottomRight"
            >
              <Icon type="delete" style={{ marginLeft: 8 }} />
            </Popconfirm>
          </Tooltip>
        </>
      )
    }
  ]

  return (
    <>
      <PageHeader
        title="Promotion codes"
        extra={
          <Button type="primary" onClick={() => setPromoCodeModalVisible(true)}>
            Add promotion code
          </Button>
        }
      />
      {error && <Alert type="error" showIcon message={error} closable />}
      <div style={{ padding: 20 }}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={promoCodes}
        />
      </div>
      <AddPromoCodeModal
        visible={promoCodeModalVisible}
        setVisible={setPromoCodeModalVisible}
        fetchPromoCodes={fetchPromoCodes}
        plans={plans}
      />
      <SharePromotionCodeModal
        visible={shareModalVisible}
        setVisible={setShareModalVisible}
        promoCode={selectedPromoCode}
        promoCodeName={selectedPromoCodeName}
        fetchPromoCodes={fetchPromoCodes}
      />
      <PromotionCodeDetailsModal
        visible={detailsModalVisible}
        setVisible={setDetailsModalVisible}
        promoCodeId={selectedPromoCode}
      />
    </>
  )
}
