import React from 'react'
import logo from '../assets/bantex-logo-white.png'

export default function UnauthLayout({ children }) {
  return (
    <div className="unauth-wrapper">
      <div className="unauth-content">{children}</div>
      <div className="unauth-bg">
        <div className="unauth-bg-img"></div>
      </div>
      <div className="logo">
        <img width={200} src={logo} alt="Bantex Hybrid eFiling logo" />
      </div>
    </div>
  )
}
