import React, { useState, useEffect } from 'react'
import { Modal, Table, DatePicker, Icon } from 'antd'
import api from '../lib/api'
import moment from 'moment'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../share/constants'
import { disabledDate } from '../share/helpers'

export default function AccessAttemptsModal({
  visible,
  setVisible,
  selectedUserId,
  setSelectedUserId
}) {
  const [accessAttempts, setAccessAttempts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (selectedUserId) {
      setIsLoading(true)

      // when there is endDate with no startDate,
      // startDate is still required in the QLDB query, so just use moment 0
      let params = {
        ...(startDate
          ? { startTime: startDate.startOf('day').toISOString() }
          : endDate
          ? { startTime: new Date(0).toISOString() }
          : {})
      }

      if (endDate) {
        const now = new Date()
        // endTime in QLDB query can't be after now
        const endTime = endDate.endOf('day').isAfter(now)
          ? now
          : endDate.endOf('day')

        params = { ...params, endTime: endTime.toISOString() }
      }

      api
        .getAccessAttempts(selectedUserId, params)
        .then(res => {
          const data = res.data.length
            ? res.data
                .filter(
                  // still need to add the filter on the client
                  // since the QLDB query would still include the record
                  // if the transaction's active period includes the start date
                  r =>
                    !startDate ||
                    startDate.startOf('day').isSameOrBefore(r.metadata.txTime)
                )
                .map(record => ({
                  ...record.data,
                  Id: record.metadata.txId,
                  Time: record.metadata.txTime,
                  IpAddress: record.data?.IpStack
                    ? JSON.parse(record.data.IpStack).ip
                    : '',
                  Country: record.data?.IpStack
                    ? JSON.parse(record.data.IpStack).country_name
                    : ''
                }))
                .reverse()
            : []

          setAccessAttempts(data)
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false))
    }
  }, [selectedUserId, startDate, endDate])

  const columns = [
    {
      key: 'Name',
      dataIndex: 'Name',
      title: 'Name',
      render: (text, record) => (
        <>
          <Icon
            type={
              record.AccessedByUserId === selectedUserId ? 'login' : 'unlock'
            }
            style={{ marginRight: 8 }}
          />
          {text}
        </>
      )
    },
    {
      key: 'Email',
      dataIndex: 'Email',
      title: 'Email'
    },
    {
      key: 'Time',
      dataIndex: 'Time',
      title: 'Access time',
      render: text => (text ? moment(text).format(DATE_TIME_FORMAT) : null)
    },
    {
      key: 'IpAddress',
      dataIndex: 'IpAddress',
      title: 'IP address'
    },
    {
      key: 'Country',
      dataIndex: 'Country',
      title: 'Country'
    }
  ]

  const closeModal = () => {
    setVisible(false)
    setSelectedUserId('')
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <Modal
      title="Access attempts history"
      visible={visible}
      onCancel={closeModal}
      width={900}
      footer={null}
    >
      <div style={{ marginBottom: 15 }}>
        <DatePicker
          onChange={setStartDate}
          placeholder="From"
          value={startDate}
          disabledDate={startDate => disabledDate(startDate, endDate)}
          format={DATE_FORMAT}
          style={{ marginRight: 10 }}
        />
        <DatePicker
          onChange={setEndDate}
          placeholder="To"
          value={endDate}
          disabledDate={endDate => disabledDate(startDate, endDate)}
          format={DATE_FORMAT}
        />
      </div>

      <Table
        rowKey="Id"
        loading={isLoading}
        columns={columns}
        dataSource={accessAttempts}
      />
    </Modal>
  )
}
