import React, { useState, useContext } from 'react'
import { Modal, Form, message } from 'antd'
import api from '../lib/api'
import { AdminContext } from './AuthLayout'
import TextInput from './TextInput';

const AddAdminModal = ({ visible, setVisible, fetchAdmins, form }) => {
  const { getFieldDecorator } = form
  const [isSaving, setIsSaving] = useState(false)
  const { professionalDeputyId } = useContext(AdminContext)

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onSave = () => {
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      api
        .addAdmin({
          email: values.email,
          professionalDeputyId
        })
        .then(res => {
          if (res.data.message) throw Error(res.data.message)

          setIsSaving(false)
          message.success('Successfully added admin')
          closeModal()
          fetchAdmins()
        })
        .catch(err => {
          setIsSaving(false)
          message.error(err.message || 'Failed to add admin')
          console.log(err)
        })
    })
  }

  return (
    <Modal
      title="Add admin"
      visible={visible}
      okText="Save"
      onOk={onSave}
      onCancel={closeModal}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Form.Item label="Email address">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'Enter a valid email address!'
              },
              {
                required: true,
                message: 'Enter email address'
              }
            ]
          })(<TextInput placeholder="email@example.com" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}
const WrappedAddAdminForm = Form.create({ name: 'AddAdminModal' })(
  AddAdminModal
)
export default WrappedAddAdminForm
