import React, { useState } from 'react'
import { Modal, Form, message, Tooltip, Icon } from 'antd'
import api from '../lib/api'
import TextInput from './TextInput'
import { removeHtmlTags } from '../share/helpers'
import { nanoid } from 'nanoid'

const AddPartnerCodeModal = ({
  visible,
  setVisible,
  fetchPartnerCodes,
  form
}) => {
  const { getFieldDecorator, setFieldsValue } = form
  const [isSaving, setIsSaving] = useState(false)

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOk = () => {
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)
      setIsSaving(true)
      api
        .addPartnerCode(
          JSON.stringify({
            id: values.id || nanoid(9),
            name: values.name
          })
        )
        .then(res => {
          setIsSaving(false)
          message.success('Successfully added partner code')
          closeModal()
          fetchPartnerCodes()
        })
        .catch(err => {
          setIsSaving(false)
          message.error(err.message || 'Failed to add partner code')
        })
    })
  }

  return (
    <Modal
      title="Add partner code"
      visible={visible}
      okText="Save"
      onOk={handleOk}
      onCancel={closeModal}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Form.Item label={<span>Name</span>}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Enter name'
              },
              {
                validator: (rule, value, callback) => {
                  if (value && !value.trim()) {
                    callback('Enter a valid name')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(<TextInput name="name" setFieldsValue={setFieldsValue} />)}
        </Form.Item>
        <Form.Item
          label={
            <span>
              ID{' '}
              <Tooltip title="An unique ID will be generated if this is left blank.">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('id', {
            // TODO: add regex validation follow back-end
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value && !value.trim()) {
                    callback('Enter a valid ID')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(<TextInput name="id" setFieldsValue={setFieldsValue} />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const WrappedAddPromoCodeModal = Form.create({ name: 'AddPartnerCodeModal' })(
  AddPartnerCodeModal
)
export default WrappedAddPromoCodeModal
