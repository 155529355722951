import React from 'react'
import { Button } from 'antd'
import { exportToExcel } from '../share/helpers'

export default function ExportExcel({
  fileName,
  columns,
  sourceData,
  style = {}
}) {
  return (
    <Button
      onClick={() => exportToExcel(fileName, columns, sourceData)}
      style={{ ...style }}
    >
      Export to Excel
    </Button>
  )
}
