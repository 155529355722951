import React, { useState } from 'react'
import {
  Modal,
  Form,
  Select,
  Radio,
  InputNumber,
  Checkbox,
  message,
  Tooltip,
  Icon,
  DatePicker
} from 'antd'
import api from '../lib/api'
import moment from 'moment'
import { DATE_FORMAT } from './../share/constants'
import TextInput from './TextInput'
import { removeHtmlTags } from '../share/helpers'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
}

const AddPromoCodeModal = ({
  visible,
  setVisible,
  fetchPromoCodes,
  plans,
  form
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const [isSaving, setIsSaving] = useState(false)
  const [discountType, setDiscountType] = useState('percent')
  const [isPrivateCode, setIsPrivateCode] = useState(false)
  const [appliesToPlan, setAppliesToPlan] = useState()

  const closeModal = () => {
    setVisible(false)
    form.resetFields()
    setDiscountType('percent')
    setIsPrivateCode(false)
    setAppliesToPlan()
  }

  const handleOk = () => {
    form.validateFields((err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)
      setIsSaving(true)
      api
        .createPromoCode(
          JSON.stringify({
            promotionCodeInfo: {
              ...values,
              name: values.name?.trim(),
              id: values.id?.trim(),
              amount_off: values.amount_off && values.amount_off * 100,
              currency: discountType === 'amount' ? 'usd' : undefined,
              redeem_by: values.redeem_by
                ? values.redeem_by.endOf('day').unix()
                : undefined
            },
            isPrivateCode,
            appliesToPlan
          })
        )
        .then(res => {
          setIsSaving(false)
          message.success('Successfully added promotion code')
          closeModal()
          fetchPromoCodes()
        })
        .catch(err => {
          setIsSaving(false)
          message.error(err.message || 'Failed to add promotion code')
          console.log(err)
        })
    })
  }

  return (
    <Modal
      title="Add promotion code"
      visible={visible}
      okText="Save"
      onOk={handleOk}
      onCancel={closeModal}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Form.Item
          label={
            <span>
              Promotion name{' '}
              <Tooltip title="This will appear on customers' receipts and invoices.">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Enter name'
              },
              {
                validator: (rule, value, callback) => {
                  if (value && !value.trim()) {
                    callback('Enter a valid name')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(<TextInput name="name" setFieldsValue={setFieldsValue} />)}
        </Form.Item>
        <Form.Item
          label={
            <span>
              Stripe coupon ID{' '}
              <Tooltip title="This is the promotion code which can be shared with the customers. An unique ID will be generated if this is left blank.">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('id', {
            // TODO: add regex validation follow back-end
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (value && !value.trim()) {
                    callback('Enter a valid ID')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(<TextInput name="id" setFieldsValue={setFieldsValue} />)}
        </Form.Item>
        <Form.Item label="Type">
          <Radio.Group
            onChange={e => setDiscountType(e.target.value)}
            value={discountType}
          >
            <Radio style={radioStyle} value="percent">
              Percentage discount
            </Radio>
            <Radio style={radioStyle} value="amount">
              Fixed amount discount
            </Radio>
          </Radio.Group>
        </Form.Item>
        {discountType === 'percent' && (
          <Form.Item label="Percentage off">
            {getFieldDecorator('percent_off', {
              rules: [
                {
                  required: discountType === 'percent',
                  message: 'Enter percentage off'
                }
              ]
            })(<InputNumber min={0} max={100} precision={2} />)}
          </Form.Item>
        )}
        {discountType === 'amount' && (
          <Form.Item label="Discount amount">
            {getFieldDecorator('amount_off', {
              rules: [
                {
                  required: discountType === 'amount',
                  message: 'Enter Discount amount'
                }
              ]
            })(<InputNumber min={0} precision={2} />)}
          </Form.Item>
        )}
        <Form.Item label="Duration">
          {getFieldDecorator('duration', {
            rules: [
              {
                required: true,
                message: 'Select duration'
              }
            ]
          })(
            <Select onChange={() => setAppliesToPlan()}>
              <Select.Option key="forever" value="forever">
                Forever
              </Select.Option>
              <Select.Option key="once" value="once">
                Once
              </Select.Option>
              <Select.Option key="repeating" value="repeating">
                Multiple months
              </Select.Option>
            </Select>
          )}
        </Form.Item>
        {getFieldValue('duration') === 'repeating' && (
          <Form.Item label="Number of months">
            {getFieldDecorator('duration_in_months', {
              rules: [
                {
                  required: getFieldValue('duration') === 'repeating',
                  message: 'Enter number of months'
                }
              ]
            })(<InputNumber min={0} precision={0} />)}
          </Form.Item>
        )}
        <Form.Item label="Expires on">
          {getFieldDecorator('redeem_by')(
            <DatePicker
              format={DATE_FORMAT}
              disabledDate={date => date.isBefore(moment().startOf('day'))}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>
        <Form.Item label="Applies to specific plan">
          <Select
            allowClear
            onChange={e => setAppliesToPlan(e)}
            value={appliesToPlan}
            placeholder="Select a plan"
          >
            {plans
              .filter(
                p =>
                  getFieldValue('duration') !== 'repeating' ||
                  p.nickname === 'Monthly'
              )
              .map(plan => (
                <Select.Option key={plan.id} value={plan.id}>
                  {plan.nickname} - ${plan.amount / 100}{' '}
                  {plan.currency.toUpperCase()}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={isPrivateCode}
            onChange={e => setIsPrivateCode(e.target.checked)}
          >
            Private code{' '}
            <Tooltip title="The code can only be used by the users whom it is directly shared.">
              <Icon type="question-circle-o" />
            </Tooltip>
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const WrappedAddPromoCodeModal = Form.create({ name: 'AddPromoCodeModal' })(
  AddPromoCodeModal
)
export default WrappedAddPromoCodeModal
