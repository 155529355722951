import React from 'react'
import { Form, Alert, Input, Button } from 'antd'
import { MFA_TYPES } from '../share/constants'

class MfaVerificationForm extends React.Component {
  render() {
    const {
      form,
      cognitoUser,
      mfaType,
      callbacks,
      errMsg,
      setErrMsg,
      isSubmitting,
      setIsSubmitting,
      extra,
      style,
      handleResend,
      isResending
    } = this.props

    const { getFieldDecorator } = form

    const descriptionByType = type => {
      switch (type) {
        case MFA_TYPES.TOTP:
          return 'Enter the authentication code from your Authenticator app.'
        case MFA_TYPES.SMS:
          return 'Enter the verification code in the SMS sent to your registered phone number.'
        default:
          return ''
      }
    }

    const handleCodeSubmit = e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (err) return

        setIsSubmitting(true)
        setErrMsg('')
        cognitoUser.sendMFACode(values.code, callbacks, mfaType)
      })
    }

    return (
      <Form style={style} onSubmit={handleCodeSubmit}>
        <div className="form-header">
          <h3 style={{ fontSize: 24 }}>Multi-factor authentication</h3>
          <p>{descriptionByType(mfaType)}</p>
        </div>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            afterClose={() => setErrMsg('')}
            style={{ marginBottom: 16 }}
          />
        )}
        <Form.Item style={{ marginTop: 10 }}>
          {getFieldDecorator('code', {
            rules: [
              {
                required: true,
                message: 'Code is required!'
              },
              {
                validator: (rule, value, callback) => {
                  // Code must be 6-digit number
                  const codeRegex = /^[0-9]{1,6}$/
                  if (value && !value.match(codeRegex)) {
                    callback('Code must be 6-digit number!')
                  } else {
                    callback()
                  }
                }
              }
            ]
          })(
            <Input
              placeholder="Code"
              allowClear
              autoFocus
              style={{ marginRight: 10, width: 'calc(100% - 60px)' }}
            />
          )}
          <Button
            type="primary"
            htmlType="submit"
            icon="right"
            loading={isSubmitting}
          />
        </Form.Item>
        {mfaType === MFA_TYPES.TOTP ? (
          extra
        ) : (
          <>
            <span>Didn't receive the SMS?</span>
            <Button
              type="link"
              style={{ padding: '0 0 0 0.5em' }}
              onClick={handleResend}
              loading={isResending}
            >
              Resend SMS
            </Button>
          </>
        )}
      </Form>
    )
  }
}

const MfaForm = Form.create({ name: 'mfaForm' })(MfaVerificationForm)
export default MfaForm
