export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const EXPORT_DATE_TIME_FORMAT = 'YYYYMMDDHHmmss'

export const MFA_TYPES = {
  TOTP: 'SOFTWARE_TOKEN_MFA',
  SMS: 'SMS_MFA'
}

export const UPPERCASE_REGEX = /[A-Z]/

export const LOWERCASE_REGEX = /[a-z]/

export const NUMBER_REGEX = /[0-9]/

export const SPECIAL_CHAR_REGEX = /[!-/:-@[-`{-~]/

export const USER_STATUSES = {
  CONFIRMED: 'Active',
  FORCE_CHANGE_PASSWORD: 'Pending'
}

export const PRODUCT_NAME = {
  VAULTBOX: 'vaultbox',
  BANTEX: 'Bantex',
  PROFESSIONAL_DEPUTY: 'Professional Deputy'
}