import React from 'react'
import { Table } from 'antd'

export default function AuthenticatorAppsInstructions() {
  const columns = [
    {
      key: 'app',
      dataIndex: 'app',
      title: 'Authenticator app'
    },
    {
      key: 'steps',
      dataIndex: 'steps',
      title: 'Brief steps'
    }
  ]

  const dataSource = [
    {
      app: 'Google Authenticator',
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>Open the app.</li>
          <li>Tap "Begin setup".</li>
          <li>Tap "Scan barcode".</li>
          <li>Scan the QR code.</li>
          <li>
            Enter the authentication code into the box below on this screen.
          </li>
        </ul>
      ),
      key: 'ggSteps'
    },
    {
      app: 'LastPass Authenticator',
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>Open the app.</li>
          <li>Tap the "+" button.</li>
          <li>Tap "Scan barcode".</li>
          <li>Scan the QR code.</li>
          <li>
            Enter the authentication code into the box below on this screen.
          </li>
        </ul>
      ),
      key: 'lpSteps'
    },
    {
      app: 'Microsoft Authenticator',
      steps: (
        <ul style={{ paddingLeft: '1em' }}>
          <li>Open the app.</li>
          <li>Tap the "+" button.</li>
          <li>Tap "Other (Google, Facebook, etc.)"</li>
          <li>Scan the QR code.</li>
          <li>
            Enter the authentication code into the box below on this screen.
          </li>
        </ul>
      ),
      key: 'msSteps'
    }
  ]

  return (
    <>
      <p>
        These are the brief steps to scan the QR code using some of the
        commonly-used authenticator apps.
      </p>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        style={{ margin: '10px 0' }}
      />
      <p>
        The information provided above are for convenience only and may differ
        from actual use.
      </p>
    </>
  )
}
