import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import config from '../config'
import { Modal } from 'antd'

const getUserPool = () =>
  new CognitoUserPool({
    UserPoolId: config.cognito.USER_POOL_ID,
    ClientId: config.cognito.APP_CLIENT_ID
  })

const getCognitoUser = () => {
  const pool = getUserPool()
  return pool.getCurrentUser()
}

const getUserAttributeValue = (attributes, attributeName) => {
  const attribute = attributes.find(attr => attr.Name === attributeName)
  return attribute ? attribute.Value : ''
}

const getAuthenticationDetails = (username, password) =>
  new AuthenticationDetails({ Username: username, Password: password })

const isValidSession = session => {
  const now = Math.floor(new Date() / 1000)
  const adjusted = now - session.clockDrift

  return (
    adjusted < session.accessToken.getExpiration() - 30 * 60 &&
    adjusted < session.idToken.getExpiration() - 30 * 60
  )
}

const needRefresh = session => {
  const now = Math.floor(new Date() / 1000)
  const adjusted = now - session.clockDrift

  return (
    adjusted > session.accessToken.getExpiration() - 59 * 60 &&
    adjusted > session.idToken.getExpiration() - 59 * 60 &&
    adjusted < session.accessToken.getExpiration() - 30 * 60 &&
    adjusted < session.idToken.getExpiration() - 30 * 60
  )
}

const getRefreshToken = user => {
  const keyPrefix = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${
    user.username
  }`
  const refreshTokenKey = `${keyPrefix}.refreshToken`
  if (user.storage.getItem(refreshTokenKey)) {
    return new CognitoRefreshToken({
      RefreshToken: user.storage.getItem(refreshTokenKey)
    })
  }
  return undefined
}

const getUserSession = (user, callback) => {
  if (user.username == null) {
    return callback(
      new Error('Username is null. Cannot retrieve a new session'),
      null
    )
  }

  if (
    user.signInUserSession != null &&
    isValidSession(user.signInUserSession)
  ) {
    const refreshToken = getRefreshToken(user)
    if (refreshToken?.getToken() && needRefresh(user.signInUserSession)) {
      user.refreshSession(refreshToken, (err, session) => {
        if (err) {
          console.log(err)
        } else {
          user.signInUserSession = session
        }
      })
    }
    return callback(null, user.signInUserSession)
  }

  const keyPrefix = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${
    user.username
  }`
  const idTokenKey = `${keyPrefix}.idToken`
  const accessTokenKey = `${keyPrefix}.accessToken`
  const refreshTokenKey = `${keyPrefix}.refreshToken`
  const clockDriftKey = `${keyPrefix}.clockDrift`

  if (user.storage.getItem(idTokenKey)) {
    const idToken = new CognitoIdToken({
      IdToken: user.storage.getItem(idTokenKey)
    })
    const accessToken = new CognitoAccessToken({
      AccessToken: user.storage.getItem(accessTokenKey)
    })
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: user.storage.getItem(refreshTokenKey)
    })
    const clockDrift = parseInt(user.storage.getItem(clockDriftKey), 0) || 0

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      ClockDrift: clockDrift
    }
    const cachedSession = new CognitoUserSession(sessionData)
    if (isValidSession(cachedSession)) {
      user.signInUserSession = cachedSession
      if (refreshToken?.getToken() && needRefresh(user.signInUserSession)) {
        user.refreshSession(refreshToken, (err, session) => {
          if (err) {
            console.log(err)
          } else {
            user.signInUserSession = session
          }
        })
      }
      return callback(null, user.signInUserSession)
    }

    return callback(new Error('Invalid session. Please authenticate.'), null)
  } else {
    callback(
      new Error('Local storage is missing an ID Token. Please authenticate'),
      null
    )
  }

  return undefined
}

const getUserData = (user, callback, params) => {
  getUserSession(user, (err, session) => {
    if (err || !isValidSession(session)) {
      user.signOut()
      localStorage.clear()

      Modal.info({
        title: 'Session timeout',
        content: 'Please login again to continue',
        onOk() {
          Modal.destroyAll()
          window.location.reload()
        }
      })
      return callback(err, null)
    }
    user.getUserData(callback, params)
  })
}

export {
  getUserPool,
  getCognitoUser,
  getUserAttributeValue,
  getAuthenticationDetails,
  isValidSession,
  getUserSession,
  getUserData
}
