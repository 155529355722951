import React from 'react'
import { Table } from 'antd'

export default function AuthenticatorAppsTable() {
  const columns = [
    {
      title: 'Authenticator app',
      dataIndex: 'app',
      key: 'app'
    },
    { title: 'iPhone', dataIndex: 'iphone', key: 'iphone' },
    { title: 'Android', dataIndex: 'android', key: 'android' }
  ]

  const dataSource = [
    {
      app: 'Google Authenticator',
      iphone: (
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          target="_blank"
          rel="noopener noreferrer"
        >
          App store
        </a>
      ),
      android: (
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Play store
        </a>
      ),
      key: 'google'
    },
    {
      app: 'LastPass Authenticator',
      iphone: (
        <a
          href="https://apps.apple.com/us/app/lastpass-authenticator/id1079110004"
          target="_blank"
          rel="noopener noreferrer"
        >
          App store
        </a>
      ),
      android: (
        <a
          href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Play store
        </a>
      ),
      key: 'lastpass'
    },
    {
      app: 'Microsoft Authenticator',
      iphone: (
        <a
          href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
          target="_blank"
          rel="noopener noreferrer"
        >
          App store
        </a>
      ),
      android: (
        <a
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Play store
        </a>
      ),
      key: 'microsoft'
    }
  ]

  return (
    <>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      <div style={{ marginTop: 10 }}>
        These apps and links are provided for convenience only and are not an
        endorsement or warranty of their suitability.
      </div>
    </>
  )
}
