import axios from 'axios'
import config from '../config'
import { getUserSession, isValidSession } from './cognito'
import { Modal } from 'antd'

const API = axios.create({
  baseURL: `https://${config.api.BASE_URL}`
})
let requestInterceptor

API.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response) {
      // Request made and server responded
      // response: data, status, headers
      return Promise.reject(error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error)
    }
  }
)

export const setApiInterceptor = user => {
  requestInterceptor = API.interceptors.request.use(
    function (config) {
      getUserSession(user, (err, session) => {
        if (!err && session && isValidSession(session)) {
          const idToken = session.getIdToken().getJwtToken()
          config.headers.Authorization = `Bearer ${idToken}`
        }
      })

      if (!config.headers.Authorization) {
        user.signOut()
        localStorage.clear()

        Modal.info({
          title: 'Session timeout',
          content: 'Please login again to continue',
          onOk() {
            Modal.destroyAll()
            window.location.reload()
          }
        })
      }
      return config
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )
}

export const clearApiInterceptor = () => {
  API.interceptors.request.eject(requestInterceptor)
}

export default {
  listUsers: params => API.get('/admin/users', { params }),
  listAdmins: params => API.get('/admin/admins', { params }),
  addAdmin: data => API.post('/admin/admins', data),
  updateAdmin: data => API.put('/admin/admins', data),
  addProfessionalDeputy: data => API.post('/admin/professional-deputies', data),
  updateProfessionalDeputy: data =>
    API.put('/admin/professional-deputies', data),
  getProfessionalDeputies: () => API.get('/admin/professional-deputies'),
  getProfessionalDeputy: pdId =>
    API.get(`/admin/professional-deputies/${pdId}`),
  deleteAdmin: userId => API.delete(`/admin/admins/${userId}`),
  getAccessAttempts: (userId, params) =>
    API.get(`/admin/access-attempts/${userId}`, { params }),
  sendInvitationEmails: data => API.post(`admin/users/invite`, data),
  getSignedUrl: (folder, fileId, operation) =>
    API.post(`/admin/signed-url`, { folder, fileId, operation }),
  getInvoicesByProfessionalDeputy: params =>
    API.get(`/admin/invoices`, { params }),
  getPricesByProfessionalDeputy: params => API.get(`/admin/prices`, { params }),
  getProducts: () => API.get('/admin/products'),
  createPromoCode: data => API.post('/admin/promotion-codes', data),
  getPromoCodes: () => API.get('/admin/promotion-codes'),
  sendPromotionEmails: data => API.post(`/admin/promotion-emails`, data),
  getPromoCode: codeId => API.get(`/admin/promotion-codes/${codeId}`),
  deletePromoCode: codeId => API.delete(`/admin/promotion-codes/${codeId}`),
  getPlans: productName => API.get('admin/plans', { params: { productName } }),
  addEmployee: data => API.post('/admin/employees', data),
  getEmployees: params => API.get('/admin/employees', { params }),
  delegatePdAccess: data => API.post('admin/pd-delegation', data),
  removePdDelegation: data => API.put('admin/pd-delegation', data),
  removeAppliedDiscount: data =>
    API.put(`/admin/promotion-codes/discount`, data),
  addPartnerCode: data => API.post('/admin/partner-code', data),
  getPartnerCode: () => API.get('/admin/partner-codes'),
  deletePartnerCode: code => API.delete(`/admin/partner-code/${code}`)
}
