import React, { useEffect, useState } from 'react'
import { Modal, Descriptions, Spin } from 'antd'
import api from '../lib/api'
import { DATE_FORMAT } from './../share/constants'
import moment from 'moment'

const PromotionCodeDetailsModal = ({ visible, setVisible, promoCodeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [promotionInfo, setPromotionInfo] = useState({})

  useEffect(() => {
    if (promoCodeId) {
      setIsLoading(true)
      api
        .getPromoCode(promoCodeId)
        .then(res => {
          if (res.data?.id) {
            setPromotionInfo(res.data)
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [promoCodeId])

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => {
        setVisible(false)
      }}
    >
      <Spin spinning={isLoading}>
        <Descriptions
          title="Promotion details"
          column={1}
          style={{ marginTop: 16 }}
        >
          <Descriptions.Item label="Promotion code">
            {promotionInfo.id}
          </Descriptions.Item>
          <Descriptions.Item label="Promotion name">
            {promotionInfo.name}
          </Descriptions.Item>
          {promotionInfo.percent_off && (
            <Descriptions.Item label="Percentage discount">
              {promotionInfo.percent_off}%
            </Descriptions.Item>
          )}
          {promotionInfo.amount_off && (
            <Descriptions.Item label="Fixed amount discount">
              ${promotionInfo.amount_off / 100}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Duration">
            {promotionInfo.duration === 'repeating'
              ? `${promotionInfo.duration_in_months} months`
              : promotionInfo.duration === 'once'
              ? 'Once'
              : promotionInfo.duration === 'forever'
              ? 'Forever'
              : ''}
          </Descriptions.Item>
          {promotionInfo.redeem_by && (
            <Descriptions.Item label="Expires on">
              {moment(promotionInfo.redeem_by * 1000).format(DATE_FORMAT)}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Spin>
    </Modal>
  )
}

export default PromotionCodeDetailsModal
