import React, { useState } from 'react'
import { debounce } from 'lodash'
import { Input, Form, Icon } from 'antd'

import {
  NUMBER_REGEX,
  SPECIAL_CHAR_REGEX,
  UPPERCASE_REGEX,
  LOWERCASE_REGEX
} from '../share/constants'
import styled from 'styled-components'

const passwordRules = {
  isLongEnough: 'At least 8 characters',
  hasNumber: 'At least 1 number',
  hasSpecialChar: 'At least 1 special character',
  hasUppercase: 'At least 1 uppercase letter',
  hasLowercase: 'At least 1 lowercase letter'
}

const PasswordInputWithRules = props => {
  const { form, password, title, confirmDirty, className } = props
  const { getFieldDecorator } = form
  const [passwordValidation, setPasswordValidation] = useState({})
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false)

  const validatePasswordRules = (rule, value, callback) => {
    // TODO: string length should be checked more properly (e.g. multiple spaces)
    const isLongEnough = value && value.length >= 8

    const hasNumber = NUMBER_REGEX.test(value)
    const hasSpecialChar = SPECIAL_CHAR_REGEX.test(value)
    const hasUppercase = UPPERCASE_REGEX.test(value)

    // testing undefined also return true, so need to convert to empty string
    const hasLowercase = LOWERCASE_REGEX.test(value || '')

    setPasswordValidation({
      isLongEnough,
      hasNumber,
      hasSpecialChar,
      hasUppercase,
      hasLowercase
    })

    debounce(
      callback,
      800
    )(
      !value ||
        (isLongEnough &&
          hasNumber &&
          hasSpecialChar &&
          hasUppercase &&
          hasLowercase)
        ? undefined
        : 'Invalid password'
    )
  }

  const validateToConfirmPassword = (
    rule,
    value,
    callback,
    form,
    confirmDirty
  ) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
    callback()
  }

  return (
    <Form.Item label={title} hasFeedback className={className}>
      {getFieldDecorator('password', {
        initialValue: password,
        rules: [
          {
            required: true,
            message: 'Enter your password!'
          },
          {
            validator: (rule, value, callback) =>
              validateToConfirmPassword(
                rule,
                value,
                callback,
                form,
                confirmDirty
              )
          },
          {
            validator: validatePasswordRules
          }
        ]
      })(
        <Input.Password
          onFocus={() => setPasswordRulesVisible(true)}
          onBlur={() => setPasswordRulesVisible(false)}
          autoComplete="new-password"
          maxLength={30}
        />
      )}
      {passwordRulesVisible && (
        <div className="password-rules">
          {Object.keys(passwordRules).map(key => (
            <div
              key={key}
              className={`password-rule ${
                passwordValidation[key] ? 'valid' : ''
              }`}
            >
              <Icon type="check-circle" />
              {passwordRules[key]}
            </div>
          ))}
        </div>
      )}
    </Form.Item>
  )
}

const StyledPasswordInputWithRules = styled(PasswordInputWithRules)`
  .password-rules {
    border: 1px solid #e5e7ef;
    padding: 20px;
    border-radius: 5px;
    z-index: 99;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    background: #ffffff;
    top: 40px;
    left: 0;
  }
  .password-rule {
    color: #9aa0b3;
    padding: 3px 0;
    &.valid {
      color: #02884e;
    }
    .anticon {
      margin-right: 10px;
    }
  }
  .ant-form-item-control.has-error {
    .password-rules {
      top: 55px;
    }
  }
`

export default StyledPasswordInputWithRules
